import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import { howstep1,howstep2,howstep3,client1,client2,client3,client4,client5} from '../../assets/img'
import QuickOrderPopup from "../quickOrderPopup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Howitworks() {
    const [quickBookPopup, setQuickBookPopup] = useState(false);
    var settingslogo = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "60px",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      var settingstesti = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: "60px",
        arrows: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      }
  return (
    <>
    <section id="about" className="py-7">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-us-content-blk">
                        <h4 className="text-uppercase mb-3 text-theme">About us</h4>    
                        <h2 className="font-bold mb-5">Achieving Excellence Together.</h2>
                        <p>BestatLaundry provides a dry cleaning and laundry service for Attigo Academy Trust. Our main motto is "satisfaction" and if you are looking for a high quality laundry service we will ensure to provide a reliable and quick delivery service. Our team has highly qualified staff who will take care of your clothes. Attigo staff can book and pay for their laundry online with convenient pickup and delivery times during school opening hours. We also provide additional services in ironing, shoe repairs, and alterations.</p>
                    </div>
                </div>
                <div className="offset-lg-1 col-lg-5">
                    <div className="about-us-img-blk position-relative">
                        <img className="shadow-lg img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/about-img.jpg" />
                        <div className="btm-text-blk ms-4 mb-4 bg-theme p-3 text-white position-absolute b-0 z-index-5">
                            <h2 className="display-3 mb-0">3</h2>
                            <h3>Academic Facilities</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="services" className="services-bg py-7">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <h4 className="text-uppercase mb-3 text-theme">Our Services</h4>
                    <h2 className="font-bold text-white mb-5">Achieving Excellence Together.</h2>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="project-feature-slider project-feature slick-slider">  
                    <Slider {...settings}>
                        <div className="project-item">
                            <div className="inner">
                                <img className="img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/service-1.jpg" alt="" />
                                <div className="project-info">
                                    <div className="project-content">
                                        <h2>Laundry services</h2>
                                        <p>Bestatlaundry provide the professional laundry service, Book your laundry online today within a minutes. We collect, Launder &amp; Deliver at your doorsteps.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-item">
                            <div className="inner">
                                <img className="img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/service-2.jpg" alt="" />
                                <div className="project-info">
                                    <div className="project-content">
                                        <h2>Laundry services</h2>
                                        <p>Bestatlaundry provide the professional laundry service, Book your laundry online today within a minutes. We collect, Launder &amp; Deliver at your doorsteps.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-item">
                            <div className="inner">
                                <img className="img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/service-3.jpg" alt="" />
                                <div className="project-info">
                                    <div className="project-content">
                                        <h2>Laundry services</h2>
                                        <p>Bestatlaundry provide the professional laundry service, Book your laundry online today within a minutes. We collect, Launder &amp; Deliver at your doorsteps.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>                          
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="clients" className="py-7">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="text-uppercase mb-3 text-theme">Clients</h4>
                    <h2 className="font-bold mb-5">What our customers say.</h2>
                </div>
                <div className="testi-slider">
                    <Slider {...settingstesti}>
                        <div className="testi-box">
                            <div className="testi-content shadow bg-theme text-white">
                                <p>I am really impressed with their laundry &amp; ironing service, they are providing professional service “Everything is good” Thank you for your help!</p>
                            </div>
                            <div className="testi-info">
                                <img className="rounded-circle img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/testi-1.png" alt="" />
                                <h4>Emilia Clarke<span>Staff</span></h4>
                            </div>
                        </div>
                        <div className="testi-box">
                            <div className="testi-content shadow bg-theme text-white">
                                <p>Absolutely I was really Appreciate your service and customer support is excellent. We were happily able to use it for the holidays.</p>
                            </div>
                            <div className="testi-info">
                                <img className="rounded-circle img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/testi-2.png" alt="" />
                                <h4>Micheal Sejuro<span>Staff</span></h4>
                            </div>
                        </div>
                        <div className="testi-box">
                            <div className="testi-content shadow bg-theme text-white">
                                <p>Professional laundry and dry cleaning services with the free door step pickup and door step delivery, The perfect way to remove stains.</p>
                            </div>
                            <div className="testi-info">
                                <img className="rounded-circle img-fluid" src="https://attigo.bestatlaundry.com/frontEnd/attigo_academy_trust/img/testi-3.png" alt="" />
                                <h4>Merline<span>Teacher</span></h4>
                            </div>
                        </div>
                    </Slider>  
                </div>
            </div>
        </div>
    </section>
    <hr></hr>
    <section id="academies" className="academies-wrapper py-7">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Slider {...settingslogo}>
                        <div className="partner-item text-center clearfix">
                            <div className="inner">
                                <div className="thumb">
                                    <img className="mx-auto" src={client1} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="partner-item text-center clearfix">
                            <div className="inner">
                                <div className="thumb">
                                    <img className="mx-auto" src={client2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="partner-item text-center clearfix">
                            <div className="inner">
                                <div className="thumb">
                                    <img className="mx-auto" src={client3} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="partner-item text-center clearfix">
                            <div className="inner">
                                <div className="thumb">
                                    <img className="mx-auto" src={client4} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="partner-item text-center clearfix">
                            <div className="inner">
                                <div className="thumb">
                                    <img className="mx-auto resize-img" src={client5} alt=""/>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>  
    </section>
    <div className="how-work-section d-none">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h1 className="mb-2">Quick Order</h1>
                        <div className="sub-heading">How it works ?</div>
                    </div>
                </div>
            </div>
            <div className="how-work-inner aos-init" data-aos="zoom-in-up">
                
                <div className="how-work-wrapper">
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 1</span>
                                <img src={howstep1} alt="Laundry Pickup" />                                
                            </div>
                            <div className="work-content">
                                <p>Choose <strong>when</strong> and <strong>where</strong> you wish us to collect &amp; deliver your laundry.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 2</span>
                                <img src={howstep2} alt="Laundry Collection" />
                            </div>
                            <div className="work-content">
                                <p>We <strong>collect</strong> your bag, <strong>list</strong> and <strong>clean</strong> your items according to <strong>your requirements</strong>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-inner">
                            <div className="work-img">
                                <span>Step 3</span>
                                <img src={howstep3} alt="Laundry Delivery" />
                            </div>
                            <div className="work-content">
                                <p>We <strong>deliver</strong> your items cleaned within <strong>24 hours</strong> and at the time required.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center" onClick={() => setQuickBookPopup(!quickBookPopup)}>
                        <Link to="#" className="btn howitbtn hover-btn" data-bs-toggle="modal" data-bs-target="#quickordermodal2">Order now</Link>
                    </div>
                    <div className="modal fade" id="quickordermodal2" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">

                            {quickBookPopup && (
                            <QuickOrderPopup close={() => setQuickBookPopup(false)} />
                            // <QuickOrderPopup close={() => setQuickBookPopup(false)} />
                            )}

                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Howitworks;
