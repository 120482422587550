import React, { useState } from 'react';
import Home from '../../pages/Home';
import Pricing from './../../pages/pricing';
import { Route, Routes, Link } from 'react-router-dom'
import AreaCovered from '../../pages/areaCovered';
import Header from '../header/header';
import Footer from '../footer';
import FAQ from '../../pages/faq';
import MyProfile from './../profile/myProfile/myProfile';
import MyAddress from '../profile/myAddress/myAddress';
import RushMoor from '../rushmoor';
import MyOrders from '../profile/myOrders';
import OrderView from '../profile/orderView';
import AboutUs from '../aboutUs';
import Reviews from '../reviews';
import Login from '../login';
import ProductLists from '../productLists';
import CheckOut from '../checkout';
import ForgotPassword from '../forgotPassword';
import AreaNotCovered from '../areaNotCovered';
import GuestLogin from '../guestLogin';
import ResetPassword from '../resetPassword';
import ThankYou from '../thankYou';
import AllNotifications from '../notification/allNotifications';
import EditProfile from '../profile/myProfile/editProfile';
import NewAddress from '../profile/myAddress/newAddress';
import EditAddress from '../profile/myAddress/editAddress';
import TermsAndConditions from '../termsAndConditions';
import PrivacyPolicy from '../privacyPolicy';
import CookiePolicy from '../cookiePolicy';
import ReferFriend from '../profile/referFriend';
import ShopHome from '../shopHome';
import { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import PrivateRoute from './privateRoutes';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import ScrollToTop from "../scrolltoTop/index"
import SignUp from '../signUp';


const userData = { name: "John", token: "test" }

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    () => localStorage.getItem('logged_user') !== null
  );
  const navigate = useNavigate();
  const location = useLocation();
  let url = location.pathname
  let splitURL = url. toString(). split("/")
  // window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
  // }
  // );


  useEffect(() => {
    localStorage.setItem('logged_user', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const logIn: any = () => setIsLoggedIn(true);
  const logOut = () => setIsLoggedIn(false);

  // useEffect(()=>{
  //   const userInfo = JSON.parse(localStorage.getItem('userDetails')!);
  //   if(userInfo){
  //     navigate('/myProfile');
  //   }else{
  //     navigate('/login');
  //   }
  // })
  return (
    <>
    <ScrollToTop>
      {splitURL[1] !== "shopHome" && <Header/>}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/areaCovered' element={<AreaCovered />} />
        <Route path='/notifications' element={<AllNotifications />} />
        <Route path='/faq' element={<FAQ />} />

        <Route path='/myProfile' element={<PrivateRoute> <MyProfile /> </PrivateRoute>} />
        <Route path='/editProfile' element={<PrivateRoute><EditProfile /></PrivateRoute>} />
        <Route path='/myAddress' element={<PrivateRoute><MyAddress /></PrivateRoute>} />

        <Route path='/newAddress' element={<PrivateRoute><NewAddress /></PrivateRoute>} />
        <Route path='/editAddress/:address' element={<PrivateRoute><EditAddress /></PrivateRoute>} />
        <Route path="/dry-cleaners/:location" element={<RushMoor />} />
        <Route path='/myOrders' element={<PrivateRoute><MyOrders /></PrivateRoute>} />
        <Route path='/orderView/:orderId' element={<PrivateRoute><OrderView /></PrivateRoute>} />
        <Route path='/aboutUs' element={<AboutUs />} />
        <Route path='/reviews' element={<Reviews />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signUp' element={<SignUp />} />
        <Route path='/login/:tokenref' element={<Login />} />
        <Route path='/productLists' element={<ProductLists />} />
        <Route path='/checkOut' element={<CheckOut />} />
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/areaNotCovered' element={<AreaNotCovered />} />
        <Route path='/guestLogin' element={<GuestLogin />} />
        <Route path='/resetPassword/:token' element={<ResetPassword />} />
        <Route path='/thankYou' element={<ThankYou />} />
        <Route path='/termsAndConditions' element={<TermsAndConditions />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/cookiePolicy' element={<CookiePolicy />} />
        <Route path='/referFriend' element={<ReferFriend />} />
        <Route path='/shopHome/:finalResult' element={<ShopHome/>} />
      </Routes>
      {splitURL[1] !== "shopHome" && <Footer />}
      </ScrollToTop>
    </>

  );
}

export default App;
